.swiper {
  width: 90%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  background-color: #d9d9d9;
  opacity: 1;
  width: 13px;
  height: 13px;
}

.swiper-pagination-bullet-active {
  background-color: #8cb15d;
  opacity: 1;
}

.swiper-pagination {
  position: unset !important;
  font-size: 30px;
}

.swiper-button-next,
.swiper-button-prev {
  display: none;
}

:root {
  --swiper-navigation-size: 30px;
}
