*,
*:after,
*:before {
  font-family: 'iransansxv', iransansx, tahoma;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

body {
  overflow: overlay;
  font-family: 'iransansxv', iransansx, tahoma;
  -webkit-font-feature-settings: 'ss02';
  font-feature-settings: 'ss02';
}

@supports (font-variation-settings: normal) {
  body {
    font-family: 'iransansxv', tahoma;
  }
}

@property --progress-value {
  syntax: '<number>';
  inherits: false;
  initial-value: 0;
}

@keyframes videoRecorderProgress {
  to {
    --progress-value: 100;
  }
}
